.App {
  text-align: center;
}

.site-layout .site-layout-background {
  background: #fff;
}

.table-row-dark {
  background-color: #000000;
}

.category-c-row {
  background-color: #ffd8bf;
}

.category-b-row {
  background-color: #fff7e6;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}


.approval-view .ant-radio-disabled + span {
  opacity: 1 !important;
  color: black !important;
  cursor: not-allowed;
}

.approval-view .ant-radio-disabled .ant-radio-inner::after {
  background-color: black !important; 
}

.approval-view .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 1) !important; 
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.approval-view .ant-radio-checked.ant-radio-disabled + span {
  font-weight: bold !important; 
}

.login-container {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25);
}

.login-form-button {
  width: 100%;
}

.App-header {
  background-color: #44464a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-input .css-18iikkb .ant-input-disabled {
  color: black !important;
}

:where(.css-18iikkb).ant-input-disabled, :where(.css-18iikkb).ant-input[disabled] {
  color: black !important;
}

:where(.css-18iikkb).ant-input-disabled, :where(.css-18iikkb).ant-input[disabled] {
  color: rgba(0, 0, 0, 1) !important;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-input-disabled, :where(.css-dev-only-do-not-override-18iikkb).ant-input[disabled] {
  color: rgba(0, 0, 0, 1) !important;
}

.approval-view .ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: rgba(0, 0, 0, 1) !important;
}


.approval-view .ant-form-item :disabled {
  color: rgb(0, 0, 0, 1) !important;
  cursor: not-allowed;
}

.approval-view .ant-form-item :disabled {
  color: rgb(0, 0, 0, 1) !important;
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.approval-view .ant-checkbox :disabled {
  color: rgb(0, 0, 0, 1) !important;
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.approval-view .ant-checkbox-disabled + span {
  opacity: 1 !important;
  color: black !important;
  cursor: not-allowed;
}


.bold-row {
  font-weight: bold;
}

.selected-row {
  background-color: #e4e4e4; /* esimerkki taustaväristä valitulle riville */
}


@media print { 
  .site-nav { 
    display: none!important;
   }
   .site-layout-footer { 
    display: none!important;
   } 
   .site-layout {
    display: block!important;
    padding: 0!important;
    margin: 0!important;
   }
  }